import {useUserDevice} from "@pg-mono/user-device";

export const useResponsiveLinkTarget = () => {
    const {isMobile} = useUserDevice();

    return isMobile ? "_self" : "_blank";
};
import {useUserDevice} from "@pg-mono/user-device";

export const useResponsiveLinkTarget = () => {
    const {isMobile} = useUserDevice();

    return isMobile ? "_self" : "_blank";
};
