import React, {CSSProperties, FC, useEffect} from "react";
import ReactModal, {OnAfterOpenCallback} from "react-modal";
import classNames from "classnames";

import * as cssStyle from "./ModalCore.module.css";

export type IModalCore = {
    children: React.ReactNode;
    isOpen: boolean;
    onModalClose?: onModalClose;
    closeButton?: (closeModal: onModalClose) => JSX.Element;
    contentStyle?: CSSProperties;
    overlayStyle?: CSSProperties;
    shouldCloseOnOverlayClick?: boolean;
    closeTimeout?: number;
    contentLabel?: string;
    modalRootId?: string;
    showCloseButton?: boolean;
    //  TODO: After rewrite to rp2, analyze if className props are needed
    className?: string;
    overlayClassName?: string;
    contentClassName?: string;
    onAfterOpen?: OnAfterOpenCallback;
    onAfterClose?: () => void;
};

type onModalClose = (event: React.MouseEvent | React.KeyboardEvent) => void;

export const ModalCore: FC<IModalCore> = (props) => {
    const {
        children,
        isOpen,
        onModalClose,
        closeTimeout,
        contentLabel,
        modalRootId,
        shouldCloseOnOverlayClick,
        contentStyle,
        overlayStyle,
        contentClassName,
        overlayClassName,
        className,
        closeButton,
        onAfterClose,
        onAfterOpen,
        showCloseButton = true
    } = props;

    useEffect(() => {
        if (process.env.EXEC_ENV === "browser") {
            const rootId = modalRootId ? modalRootId : "#root";
            ReactModal.setAppElement(rootId);
        }
    }, [modalRootId]);

    const closeModal = (event: React.MouseEvent | React.KeyboardEvent) => {
        if (onModalClose) {
            onModalClose(event);
        }
    };

    return (
        <ReactModal
            closeTimeoutMS={!!closeTimeout ? closeTimeout : 200}
            isOpen={isOpen}
            ariaHideApp={false}
            contentLabel={contentLabel}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            className={className}
            contentElement={
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ({style, className, ...propsWithoutStyle}, children) => (
                    <div {...propsWithoutStyle} className={classNames(className, cssStyle.defaultContentStyle, contentClassName)} style={contentStyle}>
                        {children}
                    </div>
                )
            }
            overlayElement={
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ({style, className, ...propsWithoutStyle}, children) => (
                    <div {...propsWithoutStyle} className={classNames(className, cssStyle.overlay, overlayClassName)} style={overlayStyle}>
                        {children}
                    </div>
                )
            }
            onRequestClose={closeModal}
            onAfterOpen={onAfterOpen}
            onAfterClose={onAfterClose}
        >
            <div className={cssStyle.childrenHolder}>
                {children}
                {showCloseButton && closeButton && closeButton(closeModal)}
            </div>
        </ReactModal>
    );
};
import React, {CSSProperties, FC, useEffect} from "react";
import ReactModal, {OnAfterOpenCallback} from "react-modal";
import classNames from "classnames";

import * as cssStyle from "./ModalCore.module.css";

export type IModalCore = {
    children: React.ReactNode;
    isOpen: boolean;
    onModalClose?: onModalClose;
    closeButton?: (closeModal: onModalClose) => JSX.Element;
    contentStyle?: CSSProperties;
    overlayStyle?: CSSProperties;
    shouldCloseOnOverlayClick?: boolean;
    closeTimeout?: number;
    contentLabel?: string;
    modalRootId?: string;
    showCloseButton?: boolean;
    //  TODO: After rewrite to rp2, analyze if className props are needed
    className?: string;
    overlayClassName?: string;
    contentClassName?: string;
    onAfterOpen?: OnAfterOpenCallback;
    onAfterClose?: () => void;
};

type onModalClose = (event: React.MouseEvent | React.KeyboardEvent) => void;

export const ModalCore: FC<IModalCore> = (props) => {
    const {
        children,
        isOpen,
        onModalClose,
        closeTimeout,
        contentLabel,
        modalRootId,
        shouldCloseOnOverlayClick,
        contentStyle,
        overlayStyle,
        contentClassName,
        overlayClassName,
        className,
        closeButton,
        onAfterClose,
        onAfterOpen,
        showCloseButton = true
    } = props;

    useEffect(() => {
        if (process.env.EXEC_ENV === "browser") {
            const rootId = modalRootId ? modalRootId : "#root";
            ReactModal.setAppElement(rootId);
        }
    }, [modalRootId]);

    const closeModal = (event: React.MouseEvent | React.KeyboardEvent) => {
        if (onModalClose) {
            onModalClose(event);
        }
    };

    return (
        <ReactModal
            closeTimeoutMS={!!closeTimeout ? closeTimeout : 200}
            isOpen={isOpen}
            ariaHideApp={false}
            contentLabel={contentLabel}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            className={className}
            contentElement={
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ({style, className, ...propsWithoutStyle}, children) => (
                    <div {...propsWithoutStyle} className={classNames(className, cssStyle.defaultContentStyle, contentClassName)} style={contentStyle}>
                        {children}
                    </div>
                )
            }
            overlayElement={
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ({style, className, ...propsWithoutStyle}, children) => (
                    <div {...propsWithoutStyle} className={classNames(className, cssStyle.overlay, overlayClassName)} style={overlayStyle}>
                        {children}
                    </div>
                )
            }
            onRequestClose={closeModal}
            onAfterOpen={onAfterOpen}
            onAfterClose={onAfterClose}
        >
            <div className={cssStyle.childrenHolder}>
                {children}
                {showCloseButton && closeButton && closeButton(closeModal)}
            </div>
        </ReactModal>
    );
};
