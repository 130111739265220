import React, {forwardRef} from "react";

import {FieldWrapper, getFieldState, IFieldWrapperProps} from "@pg-design/inputs-module";
import {getErrorMessage} from "@pg-design/inputs-module/src/utils/get_error_message";

import {Checkbox, ICheckboxProps} from "./Checkbox";

export type ICheckboxFieldProps = Omit<IFieldWrapperProps, "onClick"> &
    ICheckboxProps & {
        error?: string | string[];
    };

export const CheckboxField = forwardRef<HTMLInputElement, ICheckboxFieldProps>((props: ICheckboxFieldProps, inputForwardedRef) => {
    const {labelContent, message, id, className, onChange, error, ...checkboxProps} = props;

    const fieldState = getFieldState(props);
    const errorMessage = getErrorMessage(error);

    return (
        <FieldWrapper fieldState={fieldState} message={errorMessage || message} htmlFor={id} className={className}>
            <Checkbox {...checkboxProps} ref={inputForwardedRef} id={id} labelContent={labelContent} fieldState={fieldState} onChange={onChange} />
        </FieldWrapper>
    );
});
import React, {forwardRef} from "react";

import {FieldWrapper, getFieldState, IFieldWrapperProps} from "@pg-design/inputs-module";
import {getErrorMessage} from "@pg-design/inputs-module/src/utils/get_error_message";

import {Checkbox, ICheckboxProps} from "./Checkbox";

export type ICheckboxFieldProps = Omit<IFieldWrapperProps, "onClick"> &
    ICheckboxProps & {
        error?: string | string[];
    };

export const CheckboxField = forwardRef<HTMLInputElement, ICheckboxFieldProps>((props: ICheckboxFieldProps, inputForwardedRef) => {
    const {labelContent, message, id, className, onChange, error, ...checkboxProps} = props;

    const fieldState = getFieldState(props);
    const errorMessage = getErrorMessage(error);

    return (
        <FieldWrapper fieldState={fieldState} message={errorMessage || message} htmlFor={id} className={className}>
            <Checkbox {...checkboxProps} ref={inputForwardedRef} id={id} labelContent={labelContent} fieldState={fieldState} onChange={onChange} />
        </FieldWrapper>
    );
});
