import {useFormContext} from "react-hook-form";

import {IInputFieldProps, InputField as InputFieldDs} from "@pg-design/inputs-module";

type IProps = Omit<IInputFieldProps, "name" | "error"> & Required<Pick<IInputFieldProps, "name">>;

export function InputField(props: IProps) {
    const {name, ...fieldProps} = props;
    const {register, getFieldState, formState} = useFormContext<{[key: string]: unknown}>();
    const fs = getFieldState(name, formState);

    return <InputFieldDs {...register(name)} error={fs.error?.message} name={name} {...fieldProps} disabled={formState.disabled || fieldProps.disabled} />;
}
import {useFormContext} from "react-hook-form";

import {IInputFieldProps, InputField as InputFieldDs} from "@pg-design/inputs-module";

type IProps = Omit<IInputFieldProps, "name" | "error"> & Required<Pick<IInputFieldProps, "name">>;

export function InputField(props: IProps) {
    const {name, ...fieldProps} = props;
    const {register, getFieldState, formState} = useFormContext<{[key: string]: unknown}>();
    const fs = getFieldState(name, formState);

    return <InputFieldDs {...register(name)} error={fs.error?.message} name={name} {...fieldProps} disabled={formState.disabled || fieldProps.disabled} />;
}
