export const OFFER_TEST_IDS = {
    OFFER_VIEW: {
        PRICE_BUTTON: "offer-price-button",
        PROPERTY_BOX: (propertyId: string) => `property-${propertyId}`
    },
    LISTING_OFFER_BOX: {
        PROPERTY_VIEW_BUTTON: "property-view-button",
        PROPERTY_VIEW_BOX(propertyId: number) {
            return `property-${propertyId}`;
        }
    },
    LEAD_FORM: {
        PREMULTILEAD_CHECKBOX: "preMultiLead-checkbox",
        FORM_NAME_INPUT: "form-name-input",
        FORM_EMAIL_INPUT: "form-email-input",
        SEND_LEAD_BUTTON: "send-lead-button",
        CONFIRM_SEND_LEAD_BUTTON: "confirm-send-lead-button",
        PROPERTY_PRICE_REVEALED_MODAL: "property-price-revealed-modal"
    }
};
export const OFFER_TEST_IDS = {
    OFFER_VIEW: {
        PRICE_BUTTON: "offer-price-button",
        PROPERTY_BOX: (propertyId: string) => `property-${propertyId}`
    },
    LISTING_OFFER_BOX: {
        PROPERTY_VIEW_BUTTON: "property-view-button",
        PROPERTY_VIEW_BOX(propertyId: number) {
            return `property-${propertyId}`;
        }
    },
    LEAD_FORM: {
        PREMULTILEAD_CHECKBOX: "preMultiLead-checkbox",
        FORM_NAME_INPUT: "form-name-input",
        FORM_EMAIL_INPUT: "form-email-input",
        SEND_LEAD_BUTTON: "send-lead-button",
        CONFIRM_SEND_LEAD_BUTTON: "confirm-send-lead-button",
        PROPERTY_PRICE_REVEALED_MODAL: "property-price-revealed-modal"
    }
};
