import {Country} from "../region/types/Country";
import {Currency} from "../types/Currency";

export const getCurrency = (params: {country?: Country; currency?: Currency}) => {
    if (params.currency) {
        return getCurrencyAbbreviation(params.currency);
    }

    switch (params.country) {
        // unknown currency, check country
        case Country.SPAIN:
            return getCurrencyAbbreviation(Currency.EURO);
        case Country.POLAND:
        default:
            return getCurrencyAbbreviation(Currency.PLN);
    }
};

const getCurrencyAbbreviation = (currency: Currency) => {
    switch (currency) {
        case Currency.EURO:
            return "eur";
        case Currency.PLN:
        default:
            return "zł";
    }
};
import {Country} from "../region/types/Country";
import {Currency} from "../types/Currency";

export const getCurrency = (params: {country?: Country; currency?: Currency}) => {
    if (params.currency) {
        return getCurrencyAbbreviation(params.currency);
    }

    switch (params.country) {
        // unknown currency, check country
        case Country.SPAIN:
            return getCurrencyAbbreviation(Currency.EURO);
        case Country.POLAND:
        default:
            return getCurrencyAbbreviation(Currency.PLN);
    }
};

const getCurrencyAbbreviation = (currency: Currency) => {
    switch (currency) {
        case Currency.EURO:
            return "eur";
        case Currency.PLN:
        default:
            return "zł";
    }
};
