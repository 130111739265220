export enum CustomAttribute {
    EMAIL_MARKETING_CONSENT = "zgoda_marketingowa_email",
    PHONE_MARKETING_CONSENT = "zgoda_marketingowa_sms",
    UNSUBSCRIBE_URL_RP = "link_do_wypisu_rp",
    UNSUBSCRIBE_URL_GH = "link_do_wypisu_gh",
    MODULAR_HOUSES_MKT_OPTIN = "domy_modulowe",
    ARTICLE_ATTACHMENT_TITLE = "article_attachment_title"
}
export enum CustomAttribute {
    EMAIL_MARKETING_CONSENT = "zgoda_marketingowa_email",
    PHONE_MARKETING_CONSENT = "zgoda_marketingowa_sms",
    UNSUBSCRIBE_URL_RP = "link_do_wypisu_rp",
    UNSUBSCRIBE_URL_GH = "link_do_wypisu_gh",
    MODULAR_HOUSES_MKT_OPTIN = "domy_modulowe",
    ARTICLE_ATTACHMENT_TITLE = "article_attachment_title"
}
