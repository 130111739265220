import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PropertyPlanIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M1 1v8h8V1H1Zm7.467 2.667H6.333V2.6H5.8v2.667h.533V4.2h2.134v4.267H6.333V6.333H5.8v2.134H1.533v-1.6h3.2v-.534h-3.2V5.267h3.2V2.6H4.2v2.133H1.533v-3.2h6.934v2.134Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PropertyPlanIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M1 1v8h8V1H1Zm7.467 2.667H6.333V2.6H5.8v2.667h.533V4.2h2.134v4.267H6.333V6.333H5.8v2.134H1.533v-1.6h3.2v-.534h-3.2V5.267h3.2V2.6H4.2v2.133H1.533v-3.2h6.934v2.134Z" />
        </SvgIcon>
    );
};
