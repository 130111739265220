import React, {forwardRef, ReactNode, useEffect} from "react";
import classNames from "classnames";

import {IFieldState} from "@pg-design/inputs-module";
import {useIsMounted} from "@pg-mono/hooks";

import {CheckboxSize} from "../utils/CheckboxSize";
import {CheckboxDisplay} from "./CheckboxDisplay";

import {checkboxWrapperStyle, componentContainerStyle, errorLabelStyle, hiddenInputStyle, labelStyle} from "./Checkbox.module.css";

export type ICheckboxProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "checked" | "type" | "onChange" | "size"> & {
    name: string;
    checked: boolean | "half-checked";
    id?: string;
    className?: string;
    onChange: (name: string, checked: boolean, value: HTMLInputElement["value"]) => void;
    onAfterChange?: (name: string, checked: boolean, value: HTMLInputElement["value"]) => void;
    onMouseEnter?: (name: string, checked: boolean) => void;
    onMouseLeave?: (name: string, checked: boolean) => void;
    checkedMarkColor?: string;
    fieldState?: IFieldState;
    size?: CheckboxSize;
    labelContent?: ReactNode | string;
    "aria-labelledby"?: string;
};

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>((props, ref) => {
    const {checked, onChange, id, readOnly, labelContent, onAfterChange, size = "md", checkedMarkColor, onClick, ...inputProps} = props;

    const isMounted = useIsMounted();
    useEffect(() => {
        if (onAfterChange && isMounted) {
            onAfterChange?.(props.name, !!props.checked, props.value as HTMLInputElement["value"]);
        }
    }, [props.checked]);

    const classname = classNames(componentContainerStyle, props.className);

    const handleMouseEnter = () => {
        props.onMouseEnter?.(props.name, !!props.checked);
    };

    const handleMouseLeave = () => {
        props.onMouseLeave?.(props.name, !!props.checked);
    };

    return (
        <div className={classname} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={checkboxWrapperStyle}>
                <input
                    {...inputProps}
                    onClick={(e) => {
                        e.stopPropagation();

                        if (onClick) {
                            onClick(e);
                        }
                    }}
                    type="checkbox"
                    checked={!!checked}
                    onChange={({target: {name, checked, value}}) => {
                        if (!readOnly) {
                            onChange(name, checked, value);
                        }
                    }}
                    id={id}
                    ref={ref}
                    className={hiddenInputStyle}
                />
                <CheckboxDisplay checked={checked} checkedMarkColor={checkedMarkColor} readOnly={readOnly} size={size} />
            </div>
            {labelContent && (
                <label className={classNames(labelStyle, props.fieldState === "error" && errorLabelStyle)} htmlFor={id} aria-disabled={props.disabled}>
                    {labelContent}
                </label>
            )}
        </div>
    );
});
import React, {forwardRef, ReactNode, useEffect} from "react";
import classNames from "classnames";

import {IFieldState} from "@pg-design/inputs-module";
import {useIsMounted} from "@pg-mono/hooks";

import {CheckboxSize} from "../utils/CheckboxSize";
import {CheckboxDisplay} from "./CheckboxDisplay";

import {checkboxWrapperStyle, componentContainerStyle, errorLabelStyle, hiddenInputStyle, labelStyle} from "./Checkbox.module.css";

export type ICheckboxProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "checked" | "type" | "onChange" | "size"> & {
    name: string;
    checked: boolean | "half-checked";
    id?: string;
    className?: string;
    onChange: (name: string, checked: boolean, value: HTMLInputElement["value"]) => void;
    onAfterChange?: (name: string, checked: boolean, value: HTMLInputElement["value"]) => void;
    onMouseEnter?: (name: string, checked: boolean) => void;
    onMouseLeave?: (name: string, checked: boolean) => void;
    checkedMarkColor?: string;
    fieldState?: IFieldState;
    size?: CheckboxSize;
    labelContent?: ReactNode | string;
    "aria-labelledby"?: string;
};

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>((props, ref) => {
    const {checked, onChange, id, readOnly, labelContent, onAfterChange, size = "md", checkedMarkColor, onClick, ...inputProps} = props;

    const isMounted = useIsMounted();
    useEffect(() => {
        if (onAfterChange && isMounted) {
            onAfterChange?.(props.name, !!props.checked, props.value as HTMLInputElement["value"]);
        }
    }, [props.checked]);

    const classname = classNames(componentContainerStyle, props.className);

    const handleMouseEnter = () => {
        props.onMouseEnter?.(props.name, !!props.checked);
    };

    const handleMouseLeave = () => {
        props.onMouseLeave?.(props.name, !!props.checked);
    };

    return (
        <div className={classname} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={checkboxWrapperStyle}>
                <input
                    {...inputProps}
                    onClick={(e) => {
                        e.stopPropagation();

                        if (onClick) {
                            onClick(e);
                        }
                    }}
                    type="checkbox"
                    checked={!!checked}
                    onChange={({target: {name, checked, value}}) => {
                        if (!readOnly) {
                            onChange(name, checked, value);
                        }
                    }}
                    id={id}
                    ref={ref}
                    className={hiddenInputStyle}
                />
                <CheckboxDisplay checked={checked} checkedMarkColor={checkedMarkColor} readOnly={readOnly} size={size} />
            </div>
            {labelContent && (
                <label className={classNames(labelStyle, props.fieldState === "error" && errorLabelStyle)} htmlFor={id} aria-disabled={props.disabled}>
                    {labelContent}
                </label>
            )}
        </div>
    );
});
