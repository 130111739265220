import {css} from "@emotion/react";
import {Property} from "csstype";

export const display = (value: Property.Display) => css`
    display: ${value};
`;
import {css} from "@emotion/react";
import {Property} from "csstype";

export const display = (value: Property.Display) => css`
    display: ${value};
`;
