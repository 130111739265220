import React from "react";
import {Controller, ControllerProps, useFormContext} from "react-hook-form";

import {CheckboxField, ICheckboxProps} from "@pg-design/checkbox-module";

type IProps<FormValues extends object> = Omit<ControllerProps<FormValues>, "render" | "value" | "control"> & {} & Omit<
        ICheckboxProps,
        "checked" | "onChange" | "name"
    > &
    ILabelScenarios;

//  Label scenarios - provide aria-labelledby if you're not providing a labelContent
type ILabelScenarios = INoLabelScenario | ILabelProvidedScenario;

type INoLabelScenario = {
    labelContent?: never;
    "aria-labelledby": string;
};

type ILabelProvidedScenario = {
    labelContent: JSX.Element | string;
    "aria-labelledby"?: never;
};

export function CheckboxControlled<FormValues extends object>(props: IProps<FormValues>) {
    const {name, defaultValue, ...inputProps} = props;
    const {control} = useFormContext<FormValues>();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field, fieldState}) => (
                <CheckboxField
                    {...field}
                    {...inputProps}
                    checked={field.value}
                    error={fieldState.error?.message}
                    onChange={(fieldName, value) => {
                        field.onChange(value);
                    }}
                />
            )}
        />
    );
}
import React from "react";
import {Controller, ControllerProps, useFormContext} from "react-hook-form";

import {CheckboxField, ICheckboxProps} from "@pg-design/checkbox-module";

type IProps<FormValues extends object> = Omit<ControllerProps<FormValues>, "render" | "value" | "control"> & {} & Omit<
        ICheckboxProps,
        "checked" | "onChange" | "name"
    > &
    ILabelScenarios;

//  Label scenarios - provide aria-labelledby if you're not providing a labelContent
type ILabelScenarios = INoLabelScenario | ILabelProvidedScenario;

type INoLabelScenario = {
    labelContent?: never;
    "aria-labelledby": string;
};

type ILabelProvidedScenario = {
    labelContent: JSX.Element | string;
    "aria-labelledby"?: never;
};

export function CheckboxControlled<FormValues extends object>(props: IProps<FormValues>) {
    const {name, defaultValue, ...inputProps} = props;
    const {control} = useFormContext<FormValues>();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field, fieldState}) => (
                <CheckboxField
                    {...field}
                    {...inputProps}
                    checked={field.value}
                    error={fieldState.error?.message}
                    onChange={(fieldName, value) => {
                        field.onChange(value);
                    }}
                />
            )}
        />
    );
}
